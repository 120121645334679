<template>
  <div class="card">
    <div class="card-header">
      <h6>Crear Nuevo usuario</h6>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col">
          <label>Nombre</label>
          <input type="text" class="form-control" v-model="form.name">
          <label>Email</label>
          <input type="text" class="form-control" v-model="form.email">
          <label>Identificacion</label>
          <input type="text" class="form-control" v-model="form.identification">
          <label>Telefono (Sin espacios. Todo el numero pegado)</label>
          <input type="text" class="form-control" v-model="form.phone">
        </div>
        <div class="col">
          <label>Pais de origen</label>
          <input type="text" class="form-control" v-model="form.country_origin">
          <label>Pais de destino</label>
          <select name="country_destination" id="country_destination" class="form-control" v-model="form.country_destination">
            <option value="">Seleccione</option>
            <option value="Canada">Canada</option>
            <option value="Estados unidos">Estados unidos</option>
          </select>
          <label>Ocupacion actual</label>
          <input type="text" class="form-control" v-model="form.job">
          <button  class="btn bg-gradient-success mt-4 w-100" @click="create"> Crear nuevo usuario </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {post} from "@/utils/httpRequest";

export default {
  name: "index",
  data: () => ({
    form: {
      name: '',
      email: '',
      identification: '',
      phone: '',
      country_origin: '',
      country_destination: '',
      job: '',
      password: 'temporal123',
    }
  }),
  methods: {
    async create(){
      if(this.form.name !== '' &&
          this.form.identification !== '' &&
          this.form.country_origin !== '' &&
          this.form.phone !== '' &&
          this.form.country_destination !== '' &&
          this.form.job !== '' &&
          this.form.email !== ''
      ){
        this.form.name = this.form.name.trim().toLowerCase();
        this.form.email = this.form.email.trim().toLowerCase();
        const {data} = await post('users/create', this.form)
        if(data === 200){
          this.$swal({
            html: 'Usuario creado correctamente',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.clearForm()
        }
      }else{
        this.$swal({
          html: 'Todos los campos son requeridos',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    clearForm(){
      this.form = {
        name: '',
        identification: '',
        country_origin: '',
        phone: '',
        country_destination: '',
        job: '',
        password: 'temporal123',
        email: '',
      }
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .margin_top_mobile {
    margin-top: 1.5rem !important;
  }
}
</style>
